<template>
  <div class="mt-10">
    <v-footer
      tile
      flat
      class="font-weight-medium shadow-md -mt-20"
      :color="footerBar"
      :dark="$vuetify.theme.dark"
      :inset="true"
      v-if="hiddenAppBar"
    >
      <v-container>
        <v-row class="mt-20" dense>
          <v-col cols="12" md="4">
            <h6 class="white--text mb-0">Sobre nós</h6>
            <p class="body-2 grey--text text--lighten-2">
              {{ texts.footerDescription }}
            </p>
            <div class="align-center pb-2">
              <p
                class="grey--text font-weight-bold text-15 text--lighten-2 mb-0"
              >
                {{ legalInfo.fantasyName }}
              </p>
              <span class="grey--text text--lighten-2 text-12">
                {{ legalInfo.document }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h6 class="white--text">Explorar</h6>
            <div class="mb-2">
              <router-link to="/app" class="grey--text text--lighten-2">
                Home
              </router-link>
            </div>
            <div class="mb-2">
              <router-link to="/shop" class="grey--text text--lighten-2">
                Eventos
              </router-link>
            </div>
            <!-- Termos & Condições -->
            <div class="mb-2">
              <router-link
                to="/terms/contract"
                class="grey--text text--lighten-2"
              >
                Termos & Condições
              </router-link>
            </div>
            <div class="mb-2">
              <router-link
                to="/terms/privacy"
                class="grey--text text--lighten-2"
              >
                Politica de Privacidade
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="mb-5">
              <h6 class="white--text mb-1">Entre em contato com a gente!</h6>
              <div class="d-flex flex-wrap mb-2" style="gap: 0.5rem">
                <v-btn
                  target="_blank"
                  :href="`mailto://${email}`"
                  small
                  color="primary"
                >
                  <v-icon small left>mdi-email</v-icon>
                  Email
                </v-btn>
                <v-btn
                  target="_blank"
                  :href="whatsappUrl"
                  small
                  color="primary"
                  :loading="loadingWhatsapp"
                  @click.prevent="handleWhatsAppClick"
                >
                  <v-icon small left>mdi-whatsapp</v-icon>
                  Whatsapp
                </v-btn>
              </div>
            </div>
            <div>
              <h6 class="white--text mb-1">Nossas redes sociais!</h6>
              <div class="d-flex flex-wrap mb-2" style="gap: 0.5rem">
                <v-btn
                  v-for="(sn, i) in socialNetworks"
                  :key="i"
                  :href="socialNetworksItens[sn.type].urlFormatter(sn.url)"
                  small
                  depressed
                  target="_blank"
                  color="primary"
                >
                  <span
                    v-if="socialNetworksItens[sn.type].image"
                    v-html="socialNetworksItens[sn.type].image"
                    class="mr-1"
                  ></span>
                  <v-icon v-else small left>
                    {{ socialNetworksItens[sn.type].icon }}
                  </v-icon>
                  <span>
                    {{ socialNetworksItens[sn.type].prefix }}{{ sn.url }}
                  </span>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <a
              v-if="!hidePoweredBy"
              href="https://voytecnologia.com.br"
              class="d-flex gap-2 align-center"
              target="_blank"
            >
              <small class="grey--text text--lighten-2"> PoweredBy </small>
              <img
                style="width: 80px"
                src="/img/logo/voy-full-dark.png"
                alt=""
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import {
  texts,
  socialNetworks,
  hidePoweredBy,
  email,
  legalInfo,
  footerBar,
} from "@/themeConfig.js";
import RECORDER_SERVICE from "@/services/support/recorder";

export default {
  name: "Footer",
  data: () => ({
    email,
    texts,
    socialNetworks,
    hidePoweredBy,
    legalInfo,
    footerBar,
    socialNetworksItens: {
      ig: {
        id: "ig",
        name: "Instagram",
        icon: "mdi-instagram",
        urlFormatter: (url) => `https://instagram.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      fb: {
        id: "fb",
        name: "Facebook",
        icon: "mdi-facebook",
        urlFormatter: (url) => `https://facebook.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      tw: {
        id: "tw",
        name: "Twitter",
        icon: "mdi-twitter",
        urlFormatter: (url) => `https://twitter.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      yt: {
        id: "yt",
        name: "Youtube",
        icon: "mdi-youtube",
        urlFormatter: (url) => url,
        prefix: "",
        placeholder: "Digite o link do canal",
      },
      tt: {
        id: "tt",
        name: "TikTok",
        image: `<svg fill="currentColor" width="22px" height="22px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>`,
        urlFormatter: (url) => `https://tiktok.com/@${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
    },
    instances: [],
    instance: null,
    loadingWhatsapp: false,
    whatsappUrl: null,
  }),
  computed: {
    hiddenAppBar() {
      return this.$route.meta.hiddenAppBar || false;
    },
    formattedWhatsApp() {
      return this.instance?.phone || "";
    },
    platformId() {
      return process.env.VUE_APP_PLATFORM_ID;
    },
  },
  methods: {
    async fetchInstance() {
      this.loadingWhatsapp = true;
      try {
        const response = await RECORDER_SERVICE.getInstanceById();
        this.instance = response.data[0];
        this.whatsappUrl = `//wa.me/${this.instance.phone}`;
        window.open(this.whatsappUrl, "_blank");
      } catch (error) {
        console.error("Erro ao buscar instância:", error);
      } finally {
        this.loadingWhatsapp = false;
      }
    },
    handleWhatsAppClick() {
      if (!this.whatsappUrl) {
        this.fetchInstance();
      } else {
        window.open(this.whatsappUrl, "_blank");
      }
    },
  },
};
</script>
